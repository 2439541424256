import getConfig from 'next/config';

const config = getConfig();
let baseURL = 'http://localhost:8000';

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://unifrost.ru';
}

const getHost = () => {
  if (!!config) {
    if (!!config.serverRuntimeConfig.API_HOST) {
      return config.serverRuntimeConfig.API_HOST;
    } else if (!!config.publicRuntimeConfig.API_HOST) {
      return config.publicRuntimeConfig.API_HOST;
    }
  }

  return process.env.API_HOST;
};

export const host = !!getHost() ? getHost() : baseURL;
