import '@/styles/reset.scss';
import '@/styles/globals.scss';
import '@/styles/selects.css';
import 'keen-slider/keen-slider.min.css';
import 'intersection-observer';
import Head from 'next/head';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import { SettingsContext } from '@/utils/SettingsContext';
import API from '../api';

function App({ Component, pageProps, ...rest }) {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    Array.from(document.querySelectorAll('head > link[rel="stylesheet"][data-n-p]')).forEach(
      node => {
        node.removeAttribute('data-n-p');
      }
    );
    const mutationHandler = mutations => {
      mutations.forEach(({ target }) => {
        if (target.nodeName === 'STYLE') {
          if (target.getAttribute('media') === 'x') {
            target.removeAttribute('media');
          }
        }
      });
    };
    const observer = new MutationObserver(mutationHandler);
    observer.observe(document.head, {
      subtree: true,
      attributeFilter: ['media'],
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(async () => {
    const res = await API.get(`/v1/api/setting/`);
    setSettings(res.data);
  }, []);

  useEffect(() => {
    document.ondragstart = () => false;
    document.onselectstart = () => false;
    document.oncontextmenu = () => false;
  }, []);

  const getLayout = Component.getLayout || (page => page);
  const { t } = useTranslation(['common', 'layout']);
  return (
    <>
      <Head>
        <meta name="yandex-verification" content="355be7403f90b38b" />
        <link rel="preload" as="style" href="/static/fonts/fonts.css" />
        <link rel="stylesheet" href="/static/fonts/fonts.css" />

        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-NZW8XVN');`,
          }}
        />
      </Head>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NZW8XVN"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <SettingsContext.Provider value={settings}>
        {getLayout(<Component {...pageProps} />, t)}
      </SettingsContext.Provider>
    </>
  );
}

export default appWithTranslation(App);
